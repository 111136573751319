.scene-content{width:100%;display: block;margin: auto;}
.wrapping{max-width: 1080px;padding: 0 20px;margin: auto;}

.scene-heading{padding-top: 110px;text-align: center;}
.scene-heading h2 {font-weight: 400;color: #42444d;font-size: 24px;margin: 0px;}
.scene-heading h4{max-width: 370px;font-size: 16px;padding-bottom: 24px; margin: 15px auto 32px; border-bottom: 1px solid #dde3e8;color: #42444d;font-weight: 400;}

.scene-content .tabbing{display: flex;flex-direction: row;max-width: 1080px;margin: 48px auto;border-bottom: 1px solid #dde3e8;}
.scene-content .tabbing ul{padding:0px;margin:0px;}
.scene-content .tabbing li{ float: left; padding: 0 8px 23px;cursor: pointer;color: #42444d;font-size: 16px;font-weight: 600;margin-right: 32px;}
.scene-content .tabbing li.active{padding-bottom: 19px;border-bottom: 4px solid #00a657;}
.scene-content .tabbing li:last-child{margin-right: 0;}

.template-list{width:100%;display: block;overflow: hidden;}
.catgroup h1{font-weight: 700;color: #42444d;font-size: 16px;text-align: left;margin: 32px 0 24px;}

.template-list img{padding: 4px; cursor: pointer;overflow: hidden; position: relative;margin: 5px;max-width: 290px;height: 240px;}
.template-list { display: flex;flex-direction: row; justify-content: flex-start;flex-wrap: wrap; width: 100%;height: auto;}
.template-list img:hover{border: 4px solid #714197;padding: 0;}

.closeicon{position: absolute;right: 0;top:0;padding: 30px;background: #42444d url('../../images/close.png') no-repeat center center;}

/*topBar*/
.topbar {
position: absolute;
width: 100%;
height: 60px;
overflow: visible;
z-index: 2;
/*background-image: -webkit-gradient(linear, left top, right top, from(#00c6a8), color-stop(#009dc6), color-stop(#2473bf), color-stop(#81378f), color-stop(#b61473), to(#d45139) );
background-image: linear-gradient(to right, #00c6a8, #009dc6, #2473bf, #81378f, #b61473, #d45139 );*/
background: rgb(206,46,40);
    background: -moz-linear-gradient(90deg, rgba(206,46,40,1) 0%, rgba(232,203,28,1) 30%, rgba(59,159,90,1) 80%, rgba(9,161,210,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(206,46,40,1) 0%, rgba(232,203,28,1) 30%, rgba(59,159,90,1) 80%, rgba(9,161,210,1) 100%);
    background: linear-gradient(90deg, rgba(206,46,40,1) 0%, rgba(232,203,28,1) 30%, rgba(59,159,90,1) 80%, rgba(9,161,210,1) 100%);
   /* filter: progidXImageTransform.Microsoft.gradient(startColorstr="#ce2e28",endColorstr="#09a1d2",GradientType=1)*/
box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.topbar .logobox{float: left;width: 110px;height: 52px;padding: 4px 10px;}
.topbar .menubox{    float: right;padding: 12px 15px 0px 0px;}

.topbar .menubox li{float: left;padding-left: 10px;}

.topbar .menubox li a{    float: left;line-height: 30px;     padding: 3px 20px;font-size: 14px; border-radius: 20px; background: rgba(255,255,255,0.3);
    color: #ffffff;-webkit-transition: color .3s, background-color .5s;transition: color .3s, background-color .5s;}
.topbar .menubox li a:hover{    background-color: rgba(255,255,255,0.7);color: #333333}

.topbar .menubox li a.menuclose{width: 35px; height: 35px; padding: 0px;  background: rgba(255,255,255,0.4) url('../../images/close.png') no-repeat center center; transition: 0.5s;}
.topbar .menubox li a.menuclose:hover{transform: rotate(180deg);}
.topbar .menubox li.dyruplogo{    margin-right: 20px;margin-left: 20px;margin-top: -6px;}
@media screen and (max-width: 1100px) {
    .wrapping,.scene-content .tabbing{max-width:100%}
}
@media screen and (max-width: 991px) {
    .template-list{    justify-content: center;}
    .catgroup h1{text-align: center;}
    .scene-content .tabbing{    margin: 20px auto;}
    .scene-heading{padding-top: 90px;}
   
}


@media screen and (max-width: 480px) {
    .scene-content .tabbing li{    margin-right: 10px;}
    .catgroup h1{    margin: 20px 0 24px;}
    .scene-heading h4{    margin: 15px auto }
    .scene-heading{padding-top: 80px;}
}