
.popupheader{ padding: 10px; padding-right: 60px; border-bottom:1px solid #f2f2f2;}
.popupheader h3{ float: left; padding: 0px; margin: 0px; }
.popupheader .popupclose{ float: right; margin-right: -50px; }

.popupbody{ padding: 10px; min-width: 240px; width: 50vw; max-width: 600px; max-height: 80vh; overflow: auto; box-sizing: border-box;  }

.cartitems .cartitem{ display: block; padding: 10px 60px 10px 50px; border-bottom: 1px solid #f6f6f6; }
.cartitems .cartitem .color{ float: left; width: 40px; height: 40px; border-radius: 20px; margin-left: -50px; }
.cartitems .cartitem h4{ padding: 3px 0px; margin: 0px; }
.cartitems .cartitem .action{ float: right; margin-right: -60px;}
.popupbody .cartbtn{width: 100%;display: block;padding: 20px 0 15px 0;text-align: left;}

.cartbtn button{ background: transparent;box-shadow: none;outline: none;border: 1px solid transparent;padding: 10px 30px;cursor: pointer;margin-left: 30px;background: green;
    color: #fff;border-radius: 5px;transition: 0.5s;}
/*Enquiry Form*/
.enquiryform input,.enquiryform textarea{width: calc(100% - 35px);padding: 15px;box-shadow: none;background: #efefef;margin-top: 10px;margin-bottom: 10px;border:none;}
.cartbtn button.cancel{background: #e82020;}
.cartbtn button{float: left;   }

.enquiryform .cartbtn button.send{margin-left: 0;}
.enquiryform .cartbtn{ padding: 0;margin-bottom: 10px;display: block; overflow: hidden;}
.enquiryform .alert-warning{margin-bottom: 5px;border: 1px solid red;width: 85%;padding: 5px;color:red}
.enquiryform .infoText{border:1px solid green; padding: 5px;width: 85%;margin-bottom: 5px;}




