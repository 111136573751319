
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a{ color: #42444d; outline: 0px; text-decoration: none; }
a:hover, a:active{ color: #72747d; outline: 0px; }

ul, li, ul{ list-style: none; list-style-position: outside;  padding: 0px; margin: 0px; }

.clear::after{ content: ""; display: block; height: 0px; width: 100%; clear: both; overflow: hidden; }

.snakbar{ padding: 15px; background-color: rgba(0, 0, 0, 0.8); margin-bottom: 10px; border-radius: 10px; color: #ffffff; min-width: 240px; }