
/* Container */
#page-visulaizer{ height: 100vh; }

#page-content{ position: relative; float: left; width: calc(100% - 300px); height: calc(100vh - 60px); padding-top: 60px; background: #f6f6f6; }
#page-right{ float: right; width: 300px; height:calc(100vh); padding-top: 60px; box-sizing: border-box; border-left: 1px solid #f0f0f0; background-color: #ffffff; }

#page-visulaizer #topbar{ position: absolute; width: 100%; height: 60px; overflow: visible; z-index: 2; 

    /*background-image: linear-gradient(to right, #00c6a8, #009dc6, #2473bf, #81378f, #b61473, #d45139 );*/
    background: rgb(206,46,40);
    background: -moz-linear-gradient(90deg, rgba(206,46,40,1) 0%, rgba(232,203,28,1) 30%, rgba(59,159,90,1) 80%, rgba(9,161,210,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(206,46,40,1) 0%, rgba(232,203,28,1) 30%, rgba(59,159,90,1) 80%, rgba(9,161,210,1) 100%);
    background: linear-gradient(90deg, rgba(206,46,40,1) 0%, rgba(232,203,28,1) 30%, rgba(59,159,90,1) 80%, rgba(9,161,210,1) 100%);
    
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}
#page-visulaizer #topbar .logobox{ float: left; width: 110px; height: 52px; padding:4px 10px; }

#page-visulaizer #topbar .menubox{ float: right; padding: 11px 15px 0px 0px; }
#page-visulaizer #topbar .menubox li.dyruplogo{    margin-right: 20px;margin-left: 20px;margin-top: -6px;}
#page-visulaizer #topbar .menubox ul{ float: left; }
#page-visulaizer #topbar .menubox li{ float: left; padding-left: 10px; }
#page-visulaizer #topbar .menubox .loading,
#page-visulaizer #topbar .menubox .message,
#page-visulaizer #topbar .menubox li a{ float: left; line-height: 30px; padding:3px 20px 3px 3px; font-size: 14px;
    border-radius: 20px; background: rgba(255,255,255,0.3); color:#ffffff; transition: color .3s, background-color .5s;
}
#page-visulaizer #topbar .menubox li a:hover{ background-color: rgba(255,255,255,0.7); color: #333333;}

#page-visulaizer #topbar .menubox li a.menubtn::before{ content: ''; float: left; height: 30px; width: 30px; border-radius: 20px; margin-right: 8px; }
#page-visulaizer #topbar .menubox li a.menuhelp::before{
    background: #ffffff url('../../images/icon_info.png') no-repeat center center;
    background-size: contain;
}
#page-visulaizer #topbar .menubox li a.menulight{ width: 75px; text-align: center; }
#page-visulaizer #topbar .menubox li a.menulight.day::before{
    background: #ffffff url('../../images/icon_light_day.png') no-repeat center center;
    background-size: contain;
}
#page-visulaizer #topbar .menubox li a.menulight.night::before{
    background: #ffffff url('../../images/icon_light_night.png') no-repeat center center;
    background-size: contain;
}
#page-visulaizer #topbar .menubox li a.menuscene::before{
    background: #ffffff url('../../images/icon_scene.png') no-repeat center center;
    background-size: contain;
}
#page-visulaizer #topbar .menubox li a.menucart::before{
    background: #ffffff url('../../images/icon_cart.png') no-repeat center center;
    background-size: 70%;
}
#page-visulaizer #topbar .menubox li a.menuclose{ width: 35px; height: 35px; padding: 0px; 
    background: rgba(255,255,255,0.4) url('../../images/close.png') no-repeat center center; transition: 0.5s;
}
#page-visulaizer #topbar .menubox li a.menuclose:hover{transform: rotate(180deg);}

#page-visulaizer #topbar .searchbar{ height: 26px; width: 200px; padding: 5px; background-color: rgba(255,255,255,0.3); border-radius: 20px; }
#page-visulaizer #topbar .searchbar::before{ content: ''; float: left; height: 30px; width: 30px; border-radius: 20px; margin-right: 8px;    
    background: url('../../images/icon_serach.png') no-repeat center center;
    background-size: contain;
}
#page-visulaizer #topbar .searchbar input{ float: left; height: 26px; width: 145px; background: transparent; border: 0px; outline: 0px; color: #ffffff; }
#page-visulaizer #topbar .searchbar input::placeholder{
    color: rgba(255, 255, 255, 0.6);
    opacity: 1; /* Firefox */
}
#page-visulaizer #topbar .searchbar input:-ms-input-placeholder,
#page-visulaizer #topbar .searchbar input::-ms-input-placeholder{
    color: rgba(255, 255, 255, 0.6);
}

#page-visulaizer #topbar .menubox .loading{ padding-left: 40px; background-position: 12px 12px; 
    background-image: url('../../images/loader_min.gif'); background-repeat: no-repeat;
}
#page-visulaizer #topbar .menubox .message{ background: rgba(0,0,0,.7); color: rgba(255,255,0,1); }


#page-visulaizer #bottombar{ position: absolute; bottom: 20px; margin: 0px auto; left: 10px; top:80px;z-index:9 }
#page-visulaizer #bottombar .infobox{ width: auto; overflow: visible;  }
#page-visulaizer #bottombar .areathum{ width: 50px; height: 50px; margin: 10px 0px; box-sizing: border-box; 
    cursor: pointer; border-radius: 10px; overflow: hidden;  box-shadow: 0 2px 6px 0 rgba(0,0,0,.2); transition: box-shadow 1s, width 0.4s;
}

#page-visulaizer #bottombar .areathum span{ float: left;}
#page-visulaizer #bottombar .areathum .icon{ width: 44px; height: 44px; background-position: center center; background-size: cover; 
    border: 3px solid #ffffff; border-radius: 10px; }
#page-visulaizer #bottombar .areathum .icon_LEFT{ background-image: url('../../images/icon_area_left.png'); }
#page-visulaizer #bottombar .areathum .icon_RIGHT{ background-image: url('../../images/icon_area_right.png'); }
#page-visulaizer #bottombar .areathum .icon_TOP{ background-image: url('../../images/icon_area_top.png'); }
#page-visulaizer #bottombar .areathum .icon_CENTER{ background-image: url('../../images/icon_area_center.png'); }

#page-visulaizer #bottombar .areathum .title{ opacity: 0; transition: opacity 1s; background-color: #ffffff; color: #555555; padding: 0px 0px 0px 10px; 
    border-radius:0px 10px 10px 0px;  line-height: 36px; margin: 7px 0px; width: 93px; }    
#page-visulaizer #bottombar .areathum.hover{ width: 160px; box-shadow: 0 2px 6px 0 rgba(0,0,0,.6); }   
#page-visulaizer #bottombar .areathum.hover .title{ opacity: 1; }
#page-visulaizer #bottombar .areathum.selected{ width: 160px; box-shadow: 0 2px 6px 0 rgba(0,0,0,.4); }
#page-visulaizer #bottombar .areathum.selected .title{ opacity: 1; }
/*#page-visulaizer #bottombar .areathum.hascolor .title{ }*/

#productinfobar{ position: absolute; left: 10px; bottom: 20px;z-index:99 }
#productinfobar .infobox{ display: block; background-color:#ffffff; padding: 10px; box-sizing: border-box; border-radius: 10px; opacity: 0.8; 
    transition: opacity .5s, background-color .5s; 
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.4); 
    margin-bottom: 10px;
}
#productinfobar .infobox.less{ float: left; padding: 5px; border-radius: 16px;  }
#productinfobar .infobox.more{ width: 360px ;}
#productinfobar .infobox:hover{ opacity: 1; }
#productinfobar .infobox h3,
#productinfobar .infobox .addbtn,
#productinfobar .infobox .moreinfo{ float: left; padding:5px 10px; margin: 0px; background-color: rgba(0,0,0,.4); color: #ffffff; border-radius: 10px; cursor: pointer; }
#productinfobar .infobox h3{ min-width: 100px;}
#productinfobar .infobox .addbtn{ float: right; }
#productinfobar .infobox .moreinfo{ clear: both; margin-top: 15px; }

#productinfobar .moreinfobox{ display: block; overflow: hidden; height: 150px; transition: height 0.5s; background: white; box-sizing: border-box; border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.4); 
}
#productinfobar .moreinfobox .moreinfoin{ height: 150px;  }
#productinfobar .moreinfobox.hide{ height: 0px; }
#productinfobar .moreinfobox .halfbox{ float: left; width: 50%; }

#productinfobar .moreinfobox .tabheader{ display: block; border-bottom: 1px solid #e6e6e6; }
#productinfobar .moreinfobox .tabheader li{ float: left; padding: 10px 10px; width: 160px; text-align: center; box-sizing: border-box; height: 40px; cursor: pointer; }
#productinfobar .moreinfobox .tabheader li.active{ border-bottom: 3px solid #00c6a8;}

#productinfobar .moreinfobox .tabbody{ padding: 10px; }
#productinfobar .moreinfobox p{ padding: 0px; margin: 0px; margin-bottom: 10px; }
#productinfobar .moreinfobox .relatedproduct{ float: left; padding: 5px; box-sizing: border-box; }
#productinfobar .moreinfobox .relatedproduct div{ height: 60px ; cursor: pointer; }
#productinfobar .moreinfobox .schemeslinks{ float: left; padding: 5px; width: 33%; box-sizing: border-box; }
#productinfobar .moreinfobox .schemeslinks a{ display: block; padding: 4px 0px; }
#productinfobar .moreinfobox .schemeslinks a.active{ color: #2473bf; }
#productinfobar .moreinfobox .schemescolor{ float: right; width: 150px; box-sizing: border-box; }
#productinfobar .moreinfobox .schemescolor div{ float: left; width: 50px; height: 42px; }
#productinfobar .moreinfobox .schemescolor .schemesref{ cursor: pointer; }

#page-visulaizer #rightbox{ display: block; }
#page-visulaizer #rightbox h3{ padding:0px 0px 20px 0px; margin: 0px; font-size: 14px; }


#rightboxcontent{ height: calc(100vh - 70px); overflow: auto!important; margin-top: 10px; scroll-behavior: smooth; }

#page-visulaizer .collectionbox{ padding:15px; border-bottom: 1px solid #f2f2f2; }
#page-visulaizer .collectionbox li{ float: left; padding: 5px 5px; background-color: #ffffff; margin:0px 7px 10px 0px; border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15); cursor: pointer; transition: 0.5s;}
#page-visulaizer .collectionbox li .color{ float: left; width: 18px; height: 18px; border-radius: 20px; border:1px solid #f6f6f6; }
#page-visulaizer .collectionbox li .title{ float: left; line-height: 20px; padding-left: 5px;  }
#page-visulaizer .collectionbox li:hover{ background-color: #f6f6f6;    box-shadow: 0px 4px 2px 0px rgba(0,0,0,0.2);    transform: translateY(-1px);}
#page-visulaizer .productbox{ padding: 15px; border-bottom: 1px solid #f2f2f2;background:#ffffff }
#page-visulaizer .productbox .titlebar{ padding-right: 50px; }
#page-visulaizer .productbox .titlebar h3{ float: left;}
#page-visulaizer .productbox .titlebar .showmore{ float: right; margin-right: -50px; padding:5px 8px; border: 0px;  background: rgba(0,0,0,0.1); color:#1db123; 
    border-radius: 15px; line-height: 14px; font-size: 12px; width: 35px; text-align: center;
}
#page-visulaizer .productbox .productcolor{ float: left; width: 10%; height: 26px; margin: 1px 1px 1px 1px;
    background-repeat: no-repeat; background-size: cover; box-sizing: border-box; cursor: pointer; transition:0.5s
}
#page-visulaizer .productbox .productcolor:hover,
#page-visulaizer .productbox .productcolor:active{ box-shadow: 0 0px 5px 0 rgba(0,0,0,0.65);  transform: translateY(-1px)}
#page-visulaizer #vzcanvas{ width: 100%; height: calc(100vh - 60px); overflow: hidden; }

/*Share Icon Style*/
.social_shareicon{position: absolute;top: 0;bottom: 0;align-items: center;display: flex;margin-top: 150px;left: 10px;}
.social_shareicon ul{position: absolute;display: inherit;transition: 0.5s;left: -500px; }
.social_shareicon.active ul{left: 45px;}
.share_list_icon li{margin-right: 15px;display: inline-block;position: relative;z-index: 10;}
.share_list_icon li:focus,.share_list_icon li .react-share__ShareButton:focus{outline: none;}

.social_shareicon .innrshare{background-image: url('../../images/share.png');background-repeat: no-repeat;
    width:30px;height:32px;margin-right: 30px;cursor: pointer;position: relative;z-index:10;border: none;
    background-color: transparent;margin-top: -4px;}

/*Joy rider*/

button:focus{outline: none;}
.customskip{position: absolute;bottom: 15px;left: 15px;background: blue;border: none;color: #fff;padding: 10px 25px;border-radius: 5px;}
.react-joyride__tooltip button{padding: 10px 25px!important;font-size: 15px;}
.final.customskip{left: 0;right: 0;align-items: center; display: flex;width: 100%;max-width: 90px;margin: -9px auto;top: 20p;background-color: rgb(255, 0, 68);}

/*Responsive Screen*/

@media screen and (max-width: 991px){

    #page-content{ width: 100%;height:auto}
    #page-visulaizer #topbar .menubox li a{padding: 3px 10px 3px 3px;font-size:0px;background:transparent}
    #page-visulaizer #topbar .menubox li a.menulight{width:auto}
    .psearchbar{display: none;}
    #page-visulaizer #topbar .menubox li{padding-left: 4px;}
    #page-visulaizer #topbar .menubox li a:hover{ background-color: transparent}
    #productinfobar .moreinfo,.social_shareicon{display: none;}
    /*Right Bar*/
    #page-right{float: none; overflow: hidden; margin: auto; height: auto; border:none; width:100%; max-width:400px; }
    #page-visulaizer .collectionbox li{    padding: 7px 7px;}
    #page-visulaizer .productbox .productcolor{height: 40px;}
    #rightboxcontent{margin-top: 10px;height: auto;}
    #page-visulaizer #vzcanvas{    height: auto}
    /* Bottom Bar*/
    .areathum .title{display: none;}
    #page-visulaizer #bottombar .areathum.hover,#page-visulaizer #bottombar .areathum.selected{width: 50px;box-shadow: none}
    #page-visulaizer #bottombar .areathum.hover .icon{border-color:#e0829e}
    #page-visulaizer #bottombar{position: relative;top:auto;left:auto;bottom:auto;padding: 10px 0;width: 100%;}
    #page-visulaizer #bottombar .infobox{text-align: center;width:100%}
    #page-visulaizer #bottombar .areathum{margin: 10px 10px;display: inline-block;}
    /*Product info*/
    #productinfobar{width: 100%;position: fixed;    left: 0;bottom: 0;}
    #productinfobar .infobox{border-radius: 0px;width:100%}
    #page-right{padding-top: 20px;}
    
}
@media screen and (max-width: 599px){
.dyruplogo{display:none}
}

@media screen and (max-width: 380px){
    #page-visulaizer #topbar .menubox li a{padding: 3px 0px 3px 2px;}
    #page-visulaizer .productbox .productcolor{    height: 30px;}
    #page-visulaizer #topbar .menubox li{padding-left:0}
    #page-visulaizer #topbar .logobox{    width: 90px;}
    #page-visulaizer #topbar .logobox img{max-width: 100%;}
}