#apploading{ display: block; height: 100vh; width: 100vw; overflow: hidden; background-color: #ffffff; }

#apploading::before{
    content: "";
    width: 100%; height: 100%;
    display: block; max-width: 300px; max-height: 300px; margin: auto;
    margin-top: calc(50vh - 150px);
    background: url('../../images/loader_color.gif') no-repeat center center; 
    background-size: contain;
}

.loadingmsg{ display: block; margin: 0px auto;
    max-width: 600px; padding: 50px 40px 0px 40px; font-size: 24px; color: #888888; 
    text-align: center;
}