.errorBox{
  padding:5px;
}
.errorText{
  color:#ff0000;
  padding:2px;
}
.infoText{
  color: green;
  padding:2px;
}